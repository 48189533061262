import * as i0 from '@angular/core';
import { Injectable, Component, Input, NgModule } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import * as i2 from '@angular/common';
import { CommonModule } from '@angular/common';
import * as i3 from '@angular/forms';
import { FormsModule } from '@angular/forms';
function NgxCaptchaComponent_div_2_Template(rf, ctx) {
  if (rf & 1) {
    const _r1 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div", 2)(1, "input", 3);
    i0.ɵɵtwoWayListener("ngModelChange", function NgxCaptchaComponent_div_2_Template_input_ngModelChange_1_listener($event) {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext();
      i0.ɵɵtwoWayBindingSet(ctx_r1.captch_input, $event) || (ctx_r1.captch_input = $event);
      return i0.ɵɵresetView($event);
    });
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(2, "input", 4);
    i0.ɵɵlistener("click", function NgxCaptchaComponent_div_2_Template_input_click_2_listener() {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.checkCaptcha());
    });
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(3, "a", 5);
    i0.ɵɵlistener("click", function NgxCaptchaComponent_div_2_Template_a_click_3_listener() {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.playCaptcha());
    });
    i0.ɵɵtext(4, "\uD83D\uDD08");
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(5, "a", 6);
    i0.ɵɵlistener("click", function NgxCaptchaComponent_div_2_Template_a_click_5_listener() {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.createCaptcha());
    });
    i0.ɵɵtext(6, "\u21BB");
    i0.ɵɵelementEnd()();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵtwoWayProperty("ngModel", ctx_r1.captch_input);
    i0.ɵɵproperty("placeholder", ctx_r1.config.placeholder);
  }
}
class NgxCaptchaService {
  constructor() {
    this.captchSource = new BehaviorSubject(null);
    this.captchStatus = this.captchSource.asObservable();
  }
  setCaptchaStatus(code) {
    this.captchSource.next(code);
  }
  unsubscribe() {
    this.captchSource.next(null);
  }
  /** @nocollapse */
  static {
    this.ɵfac = function NgxCaptchaService_Factory(t) {
      return new (t || NgxCaptchaService)();
    };
  }
  /** @nocollapse */
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: NgxCaptchaService,
      factory: NgxCaptchaService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgxCaptchaService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], function () {
    return [];
  }, null);
})();
class NgxCaptchaComponent {
  constructor(captchService) {
    this.captchService = captchService;
    this.config = {};
    this.captch_input = null;
    this.code = null;
    this.resultCode = null;
    this.captchaQuestionText = '';
    this.createCaptcha = () => {
      this.captch_input = '';
      if (this.config.type == 1) {
        // text captcha
        this.code = this.resultCode = Math.random().toString(24).substring(2, this.config.length) + Math.random().toString(24).substring(2, 4).toUpperCase();
      } else if (this.config.type == 2) {
        // calculation captcha
        const num1 = Math.floor(Math.random() * 99);
        const num2 = Math.floor(Math.random() * 9);
        const operators = ['+', '-'];
        const operator = operators[Math.floor(Math.random() * operators.length)];
        this.code = num1 + operator + num2 + '=?';
        this.resultCode = operator == '+' ? num1 + num2 : num1 - num2;
      } else if (this.config.type == 3) {// dot captcha
      } else if (this.config.type == 4) {
        // count captcha
        const symbols = [{
          key: 'clouds',
          value: '☁'
        }, {
          key: 'telephones',
          value: '☎'
        }, {
          key: 'stars',
          value: '★'
        }, {
          key: 'umbrellas',
          value: '☂'
        }, {
          key: 'smiles',
          value: '☺'
        }, {
          key: 'scissors',
          value: '✂'
        }, {
          key: 'aeroplanes',
          value: '✈'
        }, {
          key: 'trucks',
          value: '⛟'
        }, {
          key: 'mountains',
          value: '⛰'
        }];
        const resultIndex = this.getRandomIntInclusive(0, symbols.length - 1);
        this.resultCode = {
          value: this.getRandomIntInclusive(1, 3),
          key: symbols[resultIndex].key
        };
        this.code = '';
        for (let i = 0; i < this.resultCode.value; i++) {
          this.code += symbols[resultIndex].value;
        }
        symbols.splice(resultIndex, 1);
        symbols.sort(() => .5 - Math.random());
        for (let i = 0; i < this.config.length - this.resultCode.value; i++) {
          this.code += symbols[i].value;
        }
        this.code = Array.from(this.code).sort(() => .5 - Math.random());
      }
      setTimeout(() => {
        const captcahCanvas = document.getElementById('captcahCanvas');
        const canvasPosition = {
          x: 0,
          y: 0,
          width: captcahCanvas.width,
          height: captcahCanvas.height
        };
        const ctx = captcahCanvas.getContext('2d');
        ctx.fillStyle = this.config.back.solid;
        ctx.fillRect(canvasPosition.x, canvasPosition.y, canvasPosition.width, canvasPosition.height);
        // ctx.font = '10px Arial';
        // ctx.fillStyle = "#000000";
        // ctx.fillText("Powered By BinsSoft", captcahCanvas.width - 95, captcahCanvas.height - 5);
        ctx.beginPath();
        if (this.isDotCaptcha()) {
          ctx.fillStyle = '#000';
          const position = {
            x: 20,
            y: 20,
            width: 30,
            height: 30,
            thickness: 1
          };
          // tslint:disable-next-line:max-line-length
          ctx.fillRect(position.x - position.thickness, position.y - position.thickness, position.width + position.thickness * 2, position.height + position.thickness * 2);
          ctx.fillStyle = '#FFF';
          ctx.fillRect(position.x, position.y, position.width, position.height);
          ctx.font = '18px ' + this.config.font.family;
          ctx.fillStyle = this.config.font.color;
          ctx.fillText('I\'m not a Robot', position.x + position.width + 10, position.y * 2 - 4);
          ctx.font = 'bold 12px ' + this.config.font.family;
          ctx.fillStyle = this.config.font.color;
          ctx.fillText('Click on all dots...', position.x + position.width + 10, position.y * 2 + 12);
          const midBox1 = {
            success: false,
            position: this.generateSmallBox(position, ctx)
          };
          const midBox2 = {
            success: false,
            position: this.generateSmallBox(position, ctx)
          };
          let clickCount = 0;
          captcahCanvas.addEventListener('mousedown', e => {
            clickCount += 1;
            const clickPosition = this.getMousePosition(captcahCanvas, e);
            this.resultCode = 1;
            if (clickPosition.x >= midBox1.position.x && clickPosition.x <= midBox1.position.x + midBox1.position.width && clickPosition.y >= midBox1.position.y && clickPosition.y <= midBox1.position.y + midBox1.position.height) {
              midBox1.success = true;
            }
            if (clickPosition.x >= midBox2.position.x && clickPosition.x <= midBox2.position.x + midBox2.position.width && clickPosition.y >= midBox2.position.y && clickPosition.y <= midBox2.position.y + midBox2.position.height) {
              midBox2.success = true;
            }
            if (clickCount === 2) {
              if (midBox1.success && midBox2.success) {
                this.captch_input = 1;
                ctx.font = '25px ' + this.config.font.family;
                ctx.fillStyle = '#158a0d';
                ctx.fillText('✓', position.x + 5, position.y + position.height - 5);
              } else {
                // it is a human
                this.captch_input = 0;
                this.createCaptcha();
              }
              this.checkCaptcha();
            }
          });
        } else if (this.isCountCaptcha()) {
          this.captchaQuestionText = 'How many "' + this.resultCode.key + '" can you see?';
          captcahCanvas.style.letterSpacing = 1 + 'px';
          ctx.font = '12px' + ' ' + this.config.font.family;
          ctx.fillStyle = this.config.font.color;
          ctx.fillText(this.captchaQuestionText, 0, canvasPosition.height - 5);
          let lastX = 0;
          ctx.font = '23px' + ' ' + this.config.font.family;
          ctx.fillStyle = this.config.font.color;
          let lastY = 60;
          Array.from(this.code).forEach((c, index) => {
            lastY = this.getRandomIntInclusive(canvasPosition.y + 30, canvasPosition.y + canvasPosition.height - 20);
            lastX += 32;
            ctx.fillText(c, lastX, lastY);
          });
          // if (this.config.back.stroke) {
          //   ctx.strokeStyle = this.config.back.stroke;
          //   for (let i = 0; i < 150; i++) {
          //     ctx.moveTo(Math.random() * 300, Math.random() * 300);
          //     ctx.lineTo(Math.random() * 300, Math.random() * 300);
          //   }
          //   ctx.stroke();
          // }
          this.resultCode = this.resultCode.value;
        } else {
          captcahCanvas.style.letterSpacing = 15 + 'px';
          ctx.font = this.config.font.size + ' ' + this.config.font.family;
          ctx.fillStyle = this.config.font.color;
          // ctx.textBaseline = 'middle';
          let lastX = 0;
          let lastY = 60;
          Array.from(this.code).forEach((c, index) => {
            if (this.config.type != 2) {
              lastY = this.getRandomIntInclusive(canvasPosition.y + 30, canvasPosition.y + canvasPosition.height - 10);
            }
            lastX += 32;
            ctx.fillText(c, lastX, lastY);
          });
          if (this.config.back.stroke) {
            ctx.strokeStyle = this.config.back.stroke;
            for (let i = 0; i < 150; i++) {
              ctx.moveTo(Math.random() * 300, Math.random() * 300);
              ctx.lineTo(Math.random() * 300, Math.random() * 300);
            }
            ctx.stroke();
          }
          this.captchaQuestionText = this.code.split('').join(' ');
        }
      }, 100);
    };
    this.isDotCaptcha = () => {
      return this.config.type == 3;
    };
    this.isCountCaptcha = () => {
      return this.config.type == 4;
    };
    this.getRandomIntInclusive = (min, max) => {
      min = Math.ceil(min);
      max = Math.floor(max);
      return Math.floor(Math.random() * (max - min + 1) + min); // The maximum is inclusive and the minimum is inclusive
    };
    this.generateSmallBox = (position, ctx) => {
      const midBoxPosition = {
        x: this.getRandomIntInclusive(position.x + 3, position.x + position.width - 6),
        y: this.getRandomIntInclusive(position.y + 3, position.y + position.height - 6),
        width: 6,
        height: 6
      };
      ctx.fillRect(midBoxPosition.x, midBoxPosition.y, midBoxPosition.width, midBoxPosition.height);
      return midBoxPosition;
    };
    this.playCaptcha = () => {
      const msg = new SpeechSynthesisUtterance(this.captchaQuestionText);
      msg.pitch = 1;
      window.speechSynthesis.speak(msg);
    };
  }
  ngOnChanges() {
    if (this.config) {
      if (!this.config.font || !this.config.font.size) {
        this.config.font.size = '40px';
      }
      if (!this.config.font || !this.config.font.family) {
        this.config.font.family = 'Arial';
      }
      if (!this.config.length) {
        this.config.length = 6;
      }
      if (this.config.length > 8) {
        this.config.length = 8;
      }
      if (!this.config.cssClass) {
        this.config.cssClass = '';
      }
      if (!this.config.type) {
        this.config.type = 1;
      }
      if (!this.config.back || !this.config.back.stroke) {
        this.config.back.stroke = '';
      }
      if (!this.config.back || !this.config.back.solid) {
        this.config.back.solid = '#fbfbfb';
      }
      if (!this.config.placeholder) {
        this.config.placeholder = 'Enter here...';
      }
      this.createCaptcha();
    }
  }
  checkCaptcha() {
    if (this.captch_input != this.resultCode) {
      this.captchService.setCaptchaStatus(false);
    } else {
      this.captchService.setCaptchaStatus(true);
    }
  }
  getMousePosition(captcahCanvas, event) {
    const rect = captcahCanvas.getBoundingClientRect();
    const x = event.clientX - rect.left;
    const y = event.clientY - rect.top;
    return {
      x,
      y
    };
  }
  /** @nocollapse */
  static {
    this.ɵfac = function NgxCaptchaComponent_Factory(t) {
      return new (t || NgxCaptchaComponent)(i0.ɵɵdirectiveInject(NgxCaptchaService));
    };
  }
  /** @nocollapse */
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: NgxCaptchaComponent,
      selectors: [["ngx-captcha"]],
      inputs: {
        config: "config"
      },
      features: [i0.ɵɵNgOnChangesFeature],
      decls: 3,
      vars: 4,
      consts: [["id", "captcahCanvas", "width", "316", "height", "80"], ["class", "captcha-actions", 4, "ngIf"], [1, "captcha-actions"], ["type", "text", 3, "ngModelChange", "ngModel", "placeholder"], ["type", "button", "value", "Check", 3, "click"], ["href", "javascript:void(0)", 1, "cpt-btn", 3, "click"], ["href", "javascript:void(0)", 1, "cpt-btn", "reload", 3, "click"]],
      template: function NgxCaptchaComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelementStart(0, "div");
          i0.ɵɵelement(1, "canvas", 0);
          i0.ɵɵtemplate(2, NgxCaptchaComponent_div_2_Template, 7, 2, "div", 1);
          i0.ɵɵelementEnd();
        }
        if (rf & 2) {
          i0.ɵɵclassMapInterpolate1("captcha-container ", ctx.config.cssClass, "");
          i0.ɵɵadvance(2);
          i0.ɵɵproperty("ngIf", ctx.config.type != 3);
        }
      },
      dependencies: [i2.NgIf, i3.DefaultValueAccessor, i3.NgControlStatus, i3.NgModel],
      styles: [".captcha-container[_ngcontent-%COMP%]{width:315px;box-shadow:1px 1px 1px #ccc}.captcha-actions[_ngcontent-%COMP%]   input[type=text][_ngcontent-%COMP%]{padding:5px;border:1px solid #CCC;border-radius:10px 0 0 10px;outline:none}.captcha-actions[_ngcontent-%COMP%]   input[type=button][_ngcontent-%COMP%]{outline:none;padding:6px;border:none;background:#ccc;border-radius:0 10px 10px 0}.captcha-actions[_ngcontent-%COMP%]   a[_ngcontent-%COMP%]{padding:10px;cursor:pointer;font-size:15px;font-weight:700;text-decoration:none;color:#222}.captcha-container[_ngcontent-%COMP%]   .powered-by[_ngcontent-%COMP%]{font-size:11px;font-family:Arial;color:#ccc;padding:5px;display:block!important}"]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgxCaptchaComponent, [{
    type: Component,
    args: [{
      selector: 'ngx-captcha',
      template: "<div class=\"captcha-container {{ config.cssClass}}\">\n    <canvas id=\"captcahCanvas\" width=\"316\" height=\"80\"></canvas>\n\n\n    <div class=\"captcha-actions\" *ngIf=\"config.type != 3\">\n  <input type=\"text\" [(ngModel)]=\"captch_input\" [placeholder]=\"config.placeholder\" />\n  <input type=\"button\" value=\"Check\" (click)=\"checkCaptcha()\" />\n  <a href=\"javascript:void(0)\" class=\"cpt-btn\" (click)=\"playCaptcha()\">\uD83D\uDD08</a>\n    <a href=\"javascript:void(0)\" class=\"cpt-btn reload\" (click)=\"createCaptcha()\">&#x21bb;</a>\n</div>\n</div>\n",
      styles: [".captcha-container{width:315px;box-shadow:1px 1px 1px #ccc}.captcha-actions input[type=text]{padding:5px;border:1px solid #CCC;border-radius:10px 0 0 10px;outline:none}.captcha-actions input[type=button]{outline:none;padding:6px;border:none;background:#ccc;border-radius:0 10px 10px 0}.captcha-actions a{padding:10px;cursor:pointer;font-size:15px;font-weight:700;text-decoration:none;color:#222}.captcha-container .powered-by{font-size:11px;font-family:Arial;color:#ccc;padding:5px;display:block!important}\n"]
    }]
  }], function () {
    return [{
      type: NgxCaptchaService
    }];
  }, {
    config: [{
      type: Input,
      args: ['config']
    }]
  });
})();
class NgxCaptchaModule {
  static forRoot() {
    return {
      ngModule: NgxCaptchaModule,
      providers: [NgxCaptchaService]
    };
  }
  /** @nocollapse */
  static {
    this.ɵfac = function NgxCaptchaModule_Factory(t) {
      return new (t || NgxCaptchaModule)();
    };
  }
  /** @nocollapse */
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: NgxCaptchaModule
    });
  }
  /** @nocollapse */
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      imports: [CommonModule, FormsModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgxCaptchaModule, [{
    type: NgModule,
    args: [{
      declarations: [NgxCaptchaComponent],
      imports: [CommonModule, FormsModule],
      exports: [NgxCaptchaComponent]
    }]
  }], null, null);
})();

/*
 * Public API Surface of ngx-captcha
 */

/**
 * Generated bundle index. Do not edit.
 */

export { NgxCaptchaComponent, NgxCaptchaModule, NgxCaptchaService };
